<script setup lang="ts">
import { useForm } from "vee-validate";
import Warning from "~/components/layouts/notice/Warning.vue";
import OTPVerifyForm from "~/components/layouts/authForms/OTPVerifyForm.vue";
import RegistrationForm from "~/components/layouts/authForms/RegistrationForm.vue";

const { user_verification } = useAuthLogics();
const statesStore = useStatesStore();
const Loader = useIsLoadingStore()

const { handleSubmit, resetForm, meta, values } = useForm({
  initialValues: {
    phone_number: '', 
  },
})

const onSubmit = handleSubmit((values) => {
  const phoneWithoutMask = values.phone_number.replace(/\D/g, '');
  user_verification('phone_number', `${statesStore.CurrentDialCode?.dial_code}${phoneWithoutMask}`);
  statesStore.UserEmail = ''
});


const OTPSend = () => {
  statesStore.OTPIsVerify = false;
  statesStore.UserCreateAccount = true;
}

</script>

<template>
  <UiTabsContent value="phone_number">
    <div v-if="statesStore.OTPIsVerify && !statesStore.UserCreateAccount">
      <UiDialogHeader v-if="statesStore.IsUserExist == 0">
        <CustomUiSectionLogoIcon class="m-auto mb-2" icon_name="ri:user-fill" />
        <UiDialogTitle class="text-center">{{ $t("Login or register") }}</UiDialogTitle>
        <UiDialogDescription class="text-center text-gray-500 border-b-gray-300 border-b pb-5">
          {{ $t("Enter your details to login") }}
        </UiDialogDescription>
      </UiDialogHeader>

      <UiDialogHeader v-if="statesStore.IsUserExist == 2">
        <CustomUiSectionLogoIcon class="m-auto mb-2" icon_name="ri:user-fill" />
        <UiDialogTitle class="text-center">{{ $t("Create a new account") }}</UiDialogTitle>
        <UiDialogDescription class="text-gray-500 border-b-gray-300 border-b pb-5 text-center">
          {{ $t("Join us on the phone number") }}
        </UiDialogDescription>
      </UiDialogHeader>
      <form @submit="onSubmit">
        <CustomUiInputPhone v-model="values.phone_number" class="mt-4 mb-6" />
      </form>
      <Warning class="mb-6" v-if="statesStore.IsWarningNotice"
        message="The user with this phone_number does not exist!" />
      <UiDialogFooter class="block">
        <UiButton :disabled="!meta.valid || Loader.isLoading" class="ys-button w-full" @click="OTPSend" v-if="statesStore.IsUserExist == 2">
          <Icon v-if="Loader.isLoading" name="svg-spinners:ring-resize" />
          <span v-else>{{ $t("Create a new account") }}</span>
        </UiButton>
        <UiButton :disabled="!meta.valid || Loader.isLoading" v-if="statesStore.IsUserExist == 0" class="ys-button w-full" type="submit"
          @click="onSubmit">
          <Icon v-if="Loader.isLoading" name="svg-spinners:ring-resize" />
          <span v-else>{{ $t("Next") }}</span>
        </UiButton>
      </UiDialogFooter>
      <div class="text-center mt-4">
        <div class="text-sm text-gray-500">
          {{ $t("Other methods") }}
        </div>
        <UiTabsList>
          <UiTabsTrigger value="email" class="text-gray-950 underline">
            {{ $t("Sign in with your email") }}
          </UiTabsTrigger>
        </UiTabsList>
      </div>
    </div>
    <OTPVerifyForm v-if="!statesStore.OTPIsVerify" />
    <RegistrationForm v-if="statesStore.OTPIsVerify && statesStore.UserCreateAccount" />
  </UiTabsContent>
</template>