<script setup lang="ts">
import {toTypedSchema} from "@vee-validate/zod";
import * as z from "zod";
import {Form, useForm} from "vee-validate";
import OTPVerifyForm from "~/components/layouts/authForms/OTPVerifyForm.vue";
import Error from "~/components/layouts/notice/Error.vue";
import {Button} from "~/components/ui/button";

const {locales, locale} = useI18n();
const Loader = useIsLoadingStore()
const currentLanguage = computed(() => {
  return locales.value.find(item => item.code === locale.value)
})

const statesStore = useStatesStore();
const {user_login} = useAuthLogics();

const formSchema = toTypedSchema(z.object({
  password: z.string()
      .min(8, {message: "Password must be at least 8 characters long"})
      .max(40, {message: "Password must be no longer than 40 characters"})
}));

const {handleSubmit, resetForm, meta, values} = useForm({
  validationSchema: formSchema,
})

const onSubmit = handleSubmit((values) => {
  user_login('email', statesStore.UserEmail, values.password);
});

const passwordVisible = ref(false);

const togglePasswordVisibility = () => {
  passwordVisible.value = !passwordVisible.value;
};

function ForgotPassword() {
  statesStore.OTPIsVerify = false;
  statesStore.UserForgotPassword = true;
}


function handleInput(event: Event) {
  const inputElement = event.target as HTMLInputElement;
  inputElement.value = inputElement.value.replace(/\s/g, '').replace(/[^a-zA-Z0-9\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g, '');
  values.password = inputElement.value;
}

</script>
<template>
  <UiTabsContent value="email">
    <div v-if="statesStore.OTPIsVerify">
      <UiDialogHeader>
        <CustomUiSectionLogoIcon class="m-auto mb-2" icon_name="ri:user-fill"/>
        <UiDialogTitle class="text-center">{{ $t("Login") }}</UiDialogTitle>
        <UiDialogDescription class="text-center text-gray-500 border-b-gray-300 border-b pb-5">
          {{ $t("Enter your details to login") }}
        </UiDialogDescription>
      </UiDialogHeader>
      <div class="mt-6">
        <UiLabel for="name">
          {{ $t("Email Address") }}
        </UiLabel>
        <div class="relative col-span-3 mb-2">
          <UiInput :disabled="statesStore.OTPIsVerify"
                   v-model="statesStore.UserEmail" id="email"
                   :class="{rtl_body_set: currentLanguage?.code === 'ar'}"
                   class="px-9 ys-form-input"
                   placeholder="hello@yourservice.com"
                   type="email"/>
          <span class="absolute inset-y-0 flex items-center justify-center px-2 text-gray-500"
              :class="{'end-0': currentLanguage?.code === 'ar', 'start-0':currentLanguage?.code != 'ar'}">
            <Icon size="19" class="ml-1" name="ri:mail-line"/>
         </span>
        </div>
      </div>
      <form @submit="onSubmit">
        <UiFormField v-slot="{ componentField }" name="password">
          <UiFormItem class="relative mt-4 mb-6">
            <UiFormLabel>{{ $t("Password") }}</UiFormLabel>
            <UiFormControl>
              <UiInput class="ys-form-input ys-password pl-9 pr-9"
                       :class="{rtl_body_set: currentLanguage?.code === 'ar'}"
                       :type="passwordVisible ? 'text' : 'password'"
                       :placeholder="$t('Password')"
                       @input="handleInput"
                       v-bind="componentField"/>
              <span :class="{'end-0': currentLanguage?.code === 'ar', 'start-0':currentLanguage?.code != 'ar'}"
                    class="absolute inset-y-0 flex items-center justify-center px-2 top-4 text-gray-500">
            <Icon size="19" class="ml-1" name="ri:lock-2-line"/>
          </span>

              <span @click="togglePasswordVisibility"
                    :class="{'start-0': currentLanguage?.code === 'ar', 'end-0':currentLanguage?.code != 'ar'}"
                    class="absolute inset-y-0 flex items-center justify-center top-4 px-2 text-gray-500">
                <Icon size="19" class="ml-1 cursor-pointer" name="ri:eye-line"/>
          </span>
            </UiFormControl>
            <UiFormMessage class="absolute start-6 top-14"/>
          </UiFormItem>
        </UiFormField>
      </form>
      <Error class="mb-6" v-if="statesStore.IsErrorNotice"
              :message="$t('Wrong password. Try again.')"/>
      <div class="flex justify-between mt-1 mb-6">
        <div class="flex items-center space-x-2">
          <UiCheckbox class="rounded" id="terms"/>
          <label for="terms" style="font-size: 14px; font-weight: normal;">
            {{ $t("Keep me logged in") }}
          </label>
        </div>
        <button @click="ForgotPassword"
                class="underline text-xs text-end p-0">
          {{ $t("Forgot password?") }}
        </button>
      </div>
      <UiDialogFooter class="block">
        <UiButton :disabled="!meta.valid || Loader.isLoading"
                  class="ys-button w-full"
                  type="submit"
                  @click="onSubmit">
          <Icon v-if="Loader.isLoading" name="svg-spinners:ring-resize" />
          <span v-else>{{ $t("Login") }}</span>
        </UiButton>
      </UiDialogFooter>
    </div>
    <OTPVerifyForm v-if="!statesStore.OTPIsVerify"/>
  </UiTabsContent>
</template>

<style scoped lang="scss">
.ys-password::placeholder {
  color: rgba(58, 62, 89, 0.5);

}
</style>