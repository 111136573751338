<script setup lang="ts">
import {toTypedSchema} from "@vee-validate/zod";
import * as z from "zod";
import {Form, useForm} from "vee-validate";
import OTPVerifyForm from "~/components/layouts/authForms/OTPVerifyForm.vue";
import Error from "~/components/layouts/notice/Error.vue";
import {Button} from "~/components/ui/button";

const statesStore = useStatesStore();
const {user_login} = useAuthLogics();
const Loader = useIsLoadingStore()

const formSchema = toTypedSchema(z.object({
  password: z.string()
      .min(8, {message: "Password must be at least 8 characters long"})
      .max(40, {message: "Password must be no longer than 40 characters"})
}));

const {handleSubmit, resetForm, meta, values} = useForm({
  validationSchema: formSchema,
})

const onSubmit = handleSubmit((values) => {
  user_login('phone_number', `${statesStore.CurrentDialCode.dial_code}${statesStore.UserPhone}`, values.password);
});

const passwordVisible = ref(false);

const togglePasswordVisibility = () => {
  passwordVisible.value = !passwordVisible.value;
};

function ForgotPassword() {
  statesStore.OTPIsVerify = false;
  statesStore.UserForgotPassword = true;
}

function handleInput(event: Event) {
  const inputElement = event.target as HTMLInputElement;
  inputElement.value = inputElement.value.replace(/\s/g, '').replace(/[^a-zA-Z0-9\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g, '');
  values.password = inputElement.value;
}


</script>
<template>
  <UiTabsContent value="phone_number">
    <div v-if="statesStore.OTPIsVerify">
      <UiDialogHeader>
        <CustomUiSectionLogoIcon class="m-auto mb-2" icon_name="ri:user-fill"/>
        <UiDialogTitle class="text-center">{{ $t("Login") }}</UiDialogTitle>
        <UiDialogDescription class="text-center text-gray-500 border-b-gray-300 border-b pb-5">
          {{ $t("Enter your details to login") }}
        </UiDialogDescription>
      </UiDialogHeader>
      <div class="mt-6">
        <UiLabel for="name">
          {{ $t("Phone Number") }}
        </UiLabel>
        <div class="relative col-span-3 mb-2">
          <UiInput :disabled="statesStore.OTPIsVerify" v-model="statesStore.UserPhone" id="phone_number"
                   class="pl-28 ys-form-input"
                   type="tel"
                   pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                   placeholder="(500) 000 0000"/>
          <span
              class="border-r absolute start-0 inset-y-0 flex items-center text-center justify-center px-2 "
              style="font-size: 14px; font-weight: normal; width: 106px">
              <UiButton type="button"
                        :disable="statesStore.OTPIsVerify"
                        class="dc-selector-button "
                        style="font-size: 14px">
                  <Icon size="18" class="mr-2" :name="'circle-flags:'+statesStore.CurrentDialCode?.flag?.toLowerCase()"/>
                  {{ statesStore.CurrentDialCode.dial_code }}
                 <Icon size="18" class="text-zinc-800" name="ri:arrow-down-s-line"/>
              </UiButton>
             </span>
        </div>
      </div>
      <form @submit="onSubmit">
        <UiFormField v-slot="{ componentField }" name="password">
          <UiFormItem class="relative mt-4 mb-6">
            <UiFormLabel>{{ $t("Password") }}</UiFormLabel>
            <UiFormControl>
              <UiInput class="ys-form-input ys-password pl-9 pr-9 "
                       :type="passwordVisible ? 'text' : 'password'"
                       :placeholder="$t('Password')"
                       @input="handleInput"
                       v-bind="componentField"/>
              <span class="absolute start-0 inset-y-0 flex items-center justify-center px-2 top-4 text-gray-500">
            <Icon size="19" class="ml-1" name="ri:lock-2-line"/>
          </span>

              <span @click="togglePasswordVisibility"
                    class="absolute end-0 inset-y-0 flex items-center justify-center top-4 px-2 text-gray-500">
            <Icon size="19" class="ml-1 cursor-pointer" name="ri:eye-line"/>
          </span>
            </UiFormControl>
            <UiFormMessage class="absolute start-6 top-14"/>
          </UiFormItem>
        </UiFormField>
      </form>
      <Error class="mb-6" v-if="statesStore.IsErrorNotice"
             :message="$t('Wrong password. Try again.')"/>
      <div class="flex justify-between mt-1 mb-6">
        <div class="flex items-center space-x-2">
          <UiCheckbox class="rounded" id="terms"/>
          <label for="terms" style="font-size: 14px; font-weight: normal;">
            {{ $t("Keep me logged in") }}
          </label>
        </div>
        <button @click="ForgotPassword"
                class="underline text-xs text-end p-0">
          {{ $t("Forgot password?") }}
        </button>
      </div>
      <UiDialogFooter class="block">
        <UiButton :disabled="!meta.valid || Loader.isLoading"
                  class="ys-button w-full"
                  type="submit"
                  @click="onSubmit">
          <Icon v-if="Loader.isLoading" name="svg-spinners:ring-resize" />
          <span v-else>{{ $t("Login") }}</span>
        </UiButton>
      </UiDialogFooter>
    </div>
    <OTPVerifyForm v-if="!statesStore.OTPIsVerify"/>
  </UiTabsContent>
</template>

<style scoped lang="scss">
.ys-password::placeholder {
  color: rgba(58, 62, 89, 0.5);

}

.dc-selector-button {
  cursor: unset;
  border: 0;
  color: rgba(82, 88, 102, 1);
  background: unset;
  text-decoration: unset;
  border-radius: 8px;
  font-size: 14px;
  height: 36px;
  padding: 8px 0 8px 0;
  gap: 4px;
}

</style>

<!--●●●●●●●●●●-->