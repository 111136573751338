<script setup lang="ts">
import OTPVerifyForm from "~/components/layouts/authForms/OTPVerifyForm.vue";
import {toTypedSchema} from "@vee-validate/zod";
import * as z from "zod";
import {useForm} from "vee-validate";
import {useUserManagementApi} from "~/services/useUserManagementApi";

const statesStore = useStatesStore();
const Loader = useIsLoadingStore()

const {userPasswordReset} = useUserManagementApi();

const formSchema = toTypedSchema(z.object({
  newPassword: z.string()
      .min(8, {message: "Password must be at least 8 characters long"})
      .max(40, {message: "Password must be no longer than 40 characters"})
      .regex(/[A-Z]/, "Password must contain least one uppercase letter")
      // .regex(/[@$!%*?& ]/, "Password must contain least one special character")
      .regex(/\d/, "Password must contain least one number"),

  confirmPassword: z.string()
      .min(8, {message: "Password must be at least 8 characters long"})
      .max(40, {message: "Password must be no longer than 40 characters"}),
}));

const {handleSubmit, resetForm, meta, errors, values} = useForm({
  validationSchema: formSchema,
})

const onSubmit = handleSubmit((values) => {
  userPasswordReset('email', statesStore.UserEmail, values.newPassword, statesStore.RequestID);
  statesStore.useClearCustomStates();
});

const newPasswordVisible = ref(false);
const confirmPasswordVisible = ref(false);

const toggleNewPasswordVisibility = () => {
  newPasswordVisible.value = !newPasswordVisible.value;
};

const toggleConfirmPasswordVisibility = () => {
  confirmPasswordVisible.value = !confirmPasswordVisible.value;
};

const newPassword = ref('');
const confirmPassword = ref('');


const UppercaseComplete = computed(() => {
  return /[A-Z]/.test(newPassword.value);
})
const NumberComplete = computed(() => {
  return /\d/.test(newPassword.value);
})
// const SpecialCharacter = computed(() => {
//   return /[@$!%*?& ]/.test(newPassword.value);
// })
const LengthCharacters = computed(() => {
  return newPassword.value.length >= 8;
})

const validatePassword = computed(() => {
  // Проверка на наличие хотя бы одной заглавной буквы
  if (!/[A-Z]/.test(newPassword.value)) {
    return 'step_1';
  }
  // Проверка на наличие хотя бы одной цифры
  if (!/\d/.test(newPassword.value)) {
    return 'step_1';
  }
  // // Проверка на наличие хотя бы одного специального символа
  // if (!/[@$!%*?& ]/.test(newPassword.value)) {
  //   return 'step_2';
  // }
  // Проверка минимальной длины пароля
  if (newPassword.value.length < 7) {
    return 'step_2';
  }
})

const passwordsMatch = computed(() => {
  if (newPassword.value.length > 7 && UppercaseComplete.value && NumberComplete.value) {
    return values.confirmPassword === values.newPassword;
  }
  return false
});

function handleInputNewPassword(event: Event) {
  const inputElement = event.target as HTMLInputElement;
  inputElement.value = inputElement.value.replace(/\s/g, '').replace(/[^a-zA-Z0-9\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g, '');
  values.newPassword = inputElement.value;
}

function handleInputConfirmPassword(event: Event) {
  const inputElement = event.target as HTMLInputElement;
  inputElement.value = inputElement.value.replace(/\s/g, '').replace(/[^a-zA-Z0-9\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g, '');
  values.confirmPassword = inputElement.value;
}

</script>
<template>
  <UiTabsContent value="email">
    <div v-if="statesStore.OTPIsVerify">
      <UiDialogHeader>
        <CustomUiSectionLogoIcon class="m-auto mb-2" icon_name="ri:door-lock-fill"/>
        <UiDialogTitle class="">{{ $t("Set a new password") }}</UiDialogTitle>
        <UiDialogDescription class="text-gray-500 border-b-gray-300 border-b pb-5">
          {{ $t("Set up a secure password to protect your account.") }}
        </UiDialogDescription>
      </UiDialogHeader>
      <UiFormField v-slot="{ componentField }" name="newPassword">
        <UiFormItem class="relative mt-4 mb-2">
          <UiFormLabel>{{ $t("Set a new password") }}</UiFormLabel>
          <UiFormControl>
            <UiInput class="ys-form-input ys-password pl-9 pr-9 "
                     :type="newPasswordVisible ? 'text' : 'password'"
                     v-model="newPassword"
                     @input="handleInputNewPassword"
                     :placeholder="$t('Password')"
                     v-bind="componentField"/>
            <span class="absolute start-0 inset-y-0 flex items-center justify-center px-2 top-4 text-gray-500">
            <Icon size="19" class="ml-1" name="ri:lock-2-line"/>
          </span>
            <span @click="toggleNewPasswordVisibility"
                  class="absolute end-0 inset-y-0 flex items-center justify-center top-4 px-2 text-gray-500">
            <Icon size="19" class="ml-1 cursor-pointer" name="ri:eye-line"/>
          </span>
          </UiFormControl>
        </UiFormItem>
      </UiFormField>
      <UiFormField v-slot="{ componentField }" name="confirmPassword">
        <UiFormItem class="relative">
          <UiFormLabel>{{ $t("Confirm Password") }}</UiFormLabel>
          <UiFormControl>
            <UiInput class="ys-form-input ys-password pl-9 pr-9 "
                     :type="confirmPasswordVisible ? 'text' : 'password'"
                     v-model="confirmPassword"
                     @input="handleInputConfirmPassword"
                     :placeholder="$t('Password')"
                     v-bind="componentField"/>
            <span class="absolute start-0 inset-y-0 flex items-center justify-center px-2 top-4 text-gray-500">
            <Icon size="19" class="ml-1" name="ri:lock-2-line"/>
          </span>
            <span @click="toggleConfirmPasswordVisibility"
                  class="absolute end-0 inset-y-0 flex items-center justify-center top-4 px-2 text-gray-500">
            <Icon size="19" class="ml-1 cursor-pointer" name="ri:eye-line"/>
          </span>
          </UiFormControl>
        </UiFormItem>
      </UiFormField>
      <div class="my-2 ys-password-progress-bar"
           :class="{step_one: validatePassword === 'step_1', step_two: validatePassword === 'step_2'}">
        <div class="single_element_one"></div>
        <div class="single_element_two"></div>
        <div class="single_element_tree"></div>
      </div>
      <p class="ys-moderate-pass-text" v-if="validatePassword === 'step_1'">{{ $t("Weak password. Must contain at least;") }}</p>
      <p class="ys-moderate-pass-text" v-if="validatePassword === 'step_2'">{{ $t("Moderate password. Must contain at least;") }}</p>
      <p class="ys-moderate-pass-text" v-if="!validatePassword">{{ $t("Strong password. Your password is secure.") }}</p>

      <!--      <p v-if='errors.newPassword' class="flex my-1 ys-moderate-pass-text">-->
      <!--        <img class="mx-2" src="/for_pass/closecirclefill.svg" alt="-"/>-->
      <!--        {{ errors.newPassword }}</p>-->

      <div v-if='errors.newPassword'>
        <p class="flex my-1 ys-moderate-pass-text">
          <Icon v-if="UppercaseComplete" size="16" class="mr-2" name="ri:checkbox-circle-fill" style="color: var(--ys-state-success)"/>
          <Icon v-if="!UppercaseComplete" size="16" class="mr-2" name="ri:close-circle-fill" style="color: var(--ys-input-text-color)"/>
          {{ $t("At least 1 uppercase") }}</p>
        <p class="flex my-1 ys-moderate-pass-text">
          <Icon v-if="NumberComplete" size="16" class="mr-2" name="ri:checkbox-circle-fill" style="color: var(--ys-state-success)"/>
          <Icon v-if="!NumberComplete" size="16" class="mr-2" name="ri:close-circle-fill" style="color: var(--ys-input-text-color)"/>
          {{ $t("At least 1 number") }}</p>
<!--        <p class="flex my-1 ys-moderate-pass-text">-->
<!--          <img v-if="!SpecialCharacter" class="mr-2" src="/for_pass/closecirclefill.svg" alt="-"/>-->
<!--          <img v-if="SpecialCharacter" class="mr-2" src="/for_pass/select-box-circle-fill.svg" alt="-"/>-->
<!--          At least 1 special characters</p>-->
        <p class="flex my-1 ys-moderate-pass-text">
          <Icon v-if="LengthCharacters" size="16" class="mr-2" name="ri:checkbox-circle-fill" style="color: var(--ys-state-success)"/>
          <Icon v-if="!LengthCharacters" size="16" class="mr-2" name="ri:close-circle-fill" style="color: var(--ys-input-text-color)"/>
          {{ $t("At least 8 characters") }}</p>
      </div>
      <UiDialogFooter class="grid mt-6">
        <UiDialogClose>
          <UiButton class="ys-button"
                    :disabled="!passwordsMatch || Loader.isLoading"
                    @click="onSubmit"
                    type="submit">
            <Icon v-if="Loader.isLoading" name="svg-spinners:ring-resize" />
            <span v-else>{{ $t("Reset Password") }}</span>
          </UiButton>
        </UiDialogClose>
      </UiDialogFooter>
    </div>
    <OTPVerifyForm v-if="!statesStore.OTPIsVerify"/>
  </UiTabsContent>
</template>

<style scoped lang="scss">

.ys-moderate-pass-text {
  color: rgba(82, 88, 102, 1);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}

.ys-password-progress-bar {
  @apply flex items-center justify-between;
  width: 376px;

  .single_element_one {
    width: 120px;
    height: 4px;
    border-radius: 1.2px;
    background: rgba(56, 199, 147, 1);
    margin-right: 10px;
  }

  .single_element_two {
    width: 120px;
    height: 4px;
    border-radius: 1.2px;
    background: rgba(56, 199, 147, 1);
    margin-right: 10px;

  }

  .single_element_tree {
    width: 120px;
    height: 4px;
    border-radius: 1.2px;
    background: rgba(56, 199, 147, 1);
  }
}

.step_one {
  .single_element_one {
    background: rgba(223, 28, 65, 1);
  }

  .single_element_two {
    background: rgba(226, 228, 233, 1);
  }

  .single_element_tree {
    background: rgba(226, 228, 233, 1);
  }
}

.step_two {
  .single_element_one {
    background: rgba(241, 123, 44, 1);
  }

  .single_element_two {
    background: rgba(241, 123, 44, 1);
  }

  .single_element_tree {
    background: rgba(226, 228, 233, 1);
  }
}

</style>